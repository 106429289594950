import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { FunnelTriggerDataInterface } from 'modules/funnels/funnel/components/funnel-rules/utils/fill-trigger-with-funnel-data'

export const splitRelatedAndUnrelatedTriggers = (
  triggers: TriggerEditType[],
  funnelStepId: FunnelTriggerDataInterface['funnelStepId'],
) => {
  const relatedTriggers: TriggerEditType[] = []
  const unrelatedTriggers: TriggerEditType[] = []
  triggers.forEach(trigger => {
    switch (trigger.type) {
      case AutomationRuleTriggersEnum.form_subscribed:
      case AutomationRuleTriggersEnum.new_sale:
      case AutomationRuleTriggersEnum.sale_canceled:
      case AutomationRuleTriggersEnum.page_visited:
      case AutomationRuleTriggersEnum.subscription_payment_failed: {
        trigger.funnelStep.id === funnelStepId
          ? relatedTriggers.push(trigger)
          : unrelatedTriggers.push(trigger)
        break
      }
      default:
        unrelatedTriggers.push(trigger)
    }
  })

  return { relatedTriggers, unrelatedTriggers }
}

import { getTimezoneDate } from './date-time-format'

export const getSecondsWithTimeZone = (seconds: number | null, timezone?: string) => {
  const userTime = getTimezoneDate(new Date(), timezone)
  const utcTime = getTimezoneDate(new Date(), 'UTC')
  const hoursDiff = userTime.getHours() - utcTime.getHours()
  const minutesDiff = userTime.getMinutes() - utcTime.getMinutes()
  const secondsDiff = hoursDiff * 3600 + minutesDiff * 60

  return seconds ? seconds - secondsDiff : null
}

export const getSecondsWithoutTimeZone = (seconds: number, timezone?: string) => {
  const userTime = getTimezoneDate(new Date(), timezone)
  const utcTime = getTimezoneDate(new Date(), 'UTC')

  const hoursDiff = userTime.getHours() - utcTime.getHours()
  const minutesDiff = userTime.getMinutes() - utcTime.getMinutes()

  const secondsDiff = hoursDiff * 3600 + minutesDiff * 60

  return seconds + secondsDiff
}

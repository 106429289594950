import { onChangeRuleStatus, onDeleteRule } from 'modules/automation-rules/api/automation-rules-api'
import {
  triggerActionLocoKeys,
  triggerDescriptionLocoKeys,
} from 'modules/automation-rules/triggers/constants/trigger-loco-keys'
import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'
import { TriggerEditType } from 'modules/automation-rules/triggers/types/automation-rule-triggers-edit-interface'
import { getTriggerIcon } from 'modules/automation-rules/triggers/utils/get-trigger-icon'
import {
  AutomationRuleApiInterface,
  AutomationRuleInterface,
} from 'modules/automation-rules/types/automation-rule-interface'
import React from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { StatusCell } from 'shared/components/table/cells/status-cell'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { KeyedMutator } from 'swr'
import { twMerge } from 'tailwind-merge'
import { FunnelTriggerDataInterface } from '../utils/fill-trigger-with-funnel-data'
import { splitRelatedAndUnrelatedTriggers } from '../utils/split-related-and-unrelated-triggers'

interface AutomationRulesTableProps {
  rules?: AutomationRuleApiInterface
  mutateRules: KeyedMutator<AutomationRuleApiInterface>
  setSelectedRuleId: (ruleId: number) => void
  mutateAvailableTriggers: KeyedMutator<AutomationRuleTriggersEnum[]>
  funnelStepId: FunnelTriggerDataInterface['funnelStepId']
}

const FunnelStepRulesTable = ({
  rules,
  mutateRules,
  setSelectedRuleId,
  mutateAvailableTriggers,
  funnelStepId,
}: AutomationRulesTableProps) => {
  const { t } = useLocoTranslation()

  const changeRuleStatus = async (rule: AutomationRuleInterface) => {
    await onChangeRuleStatus(rule.active, rule.id)
    await mutateRules(
      data =>
        data && {
          ...data,
          items: data.items.map(el => {
            if (el.id === rule.id) {
              return { ...el, active: !el.active }
            }
            return el
          }),
        },
      false,
    )
  }

  const deleteRule = async (rule: AutomationRuleInterface) => {
    await onDeleteRule(rule.id)
    await mutateRules(
      data => data && { ...data, items: data.items.filter(el => el.id !== rule.id) },
      false,
    )
    await mutateAvailableTriggers()
  }

  const columns: ColumnDefinitionInterface<AutomationRuleInterface>[] = [
    {
      key: 'triggers',
      header: '',
      widthPercent: 95,
      onRender: rule => {
        const { relatedTriggers, unrelatedTriggers } = splitRelatedAndUnrelatedTriggers(
          rule.triggers as TriggerEditType[],
          funnelStepId,
        )
        return (
          <Hint
            showHint={unrelatedTriggers.length !== 0}
            label={
              <TransLoco
                t={t}
                components={{
                  a: (
                    <LinkWithoutPrefetch
                      href={`/automation-rules/${rule.id}`}
                      className={'primary-link'}
                    />
                  ),
                }}
                i18nKey={'dashboard.automation_rule.trigger.has_non_related'}
              />
            }
          >
            <div className={'flex flex-col w-full'}>
              {relatedTriggers.map((el, idx) => (
                <div
                  key={el.type + idx}
                  className={twMerge(
                    `flex flex-col gap-1 py-3`,
                    idx !== 0 ? 'border-t border-gray/30' : 'pt-0',
                    idx + 1 === relatedTriggers.length && 'pb-0',
                  )}
                >
                  <div className={'flex items-center gap-3'}>
                    {getTriggerIcon(el.type)}
                    <div className={'flex flex-col gap-1 overflow-hidden'}>
                      <span className={'text-darkblue font-bold text-[15px] truncate'}>
                        {t(triggerActionLocoKeys[el.type])}
                      </span>
                      <span className={'truncate'}>{t(triggerDescriptionLocoKeys[el.type])}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Hint>
        )
      },
    },
    {
      key: 'active',
      header: '',
      widthPercent: 5,
      onRender: rule => <StatusCell isActive={rule.active} />,
    },
  ]
  return (
    <Table
      onRowClick={instance => setSelectedRuleId(instance.id)}
      noHeader
      actions={{
        custom: [
          {
            name: 'edit-rule',
            caption: t('dashboard.automation_rule.edit.title'),
            onActionClick: rule => setSelectedRuleId(rule.id),
          },
          {
            name: 'enable-rule',
            caption: t('dashboard.automation_rule.edit.enable'),
            shouldRender: rule => !rule.active,
            onActionRender: (opened, onClose, instance, key) => (
              <ConfirmationModal
                key={key}
                confirmationText={t('dashboard.automation_rule.edit.enable_confirm')}
                toastCaption={t('dashboard.automation_rule.edit.enable_success')}
                opened={opened}
                onClose={onClose}
                onConfirm={() => changeRuleStatus(instance)}
              />
            ),
          },
          {
            name: 'disable-rule',
            caption: t('dashboard.automation_rule.edit.disable'),
            shouldRender: rule => rule.active,
            onActionRender: (opened, onClose, instance, key) => (
              <ConfirmationModal
                key={key}
                confirmationText={t('dashboard.automation_rule.edit.disable_confirm')}
                toastCaption={t('dashboard.automation_rule.edit.disable_success')}
                opened={opened}
                onClose={onClose}
                onConfirm={() => changeRuleStatus(instance)}
              />
            ),
          },
          {
            name: 'delete-rule',
            caption: t('dashboard.automation_rule.edit.delete'),
            onActionRender: (opened, onClose, instance, key) => {
              const { unrelatedTriggers } = splitRelatedAndUnrelatedTriggers(
                instance.triggers as TriggerEditType[],
                funnelStepId,
              )

              const hasUnrelatedTriggers = unrelatedTriggers.length !== 0
              return (
                <ConfirmationModal
                  key={key}
                  toastCaption={t('dashboard.automation_rule.edit.delete_success')}
                  confirmationText={
                    hasUnrelatedTriggers ? (
                      <TransLoco
                        t={t}
                        i18nKey={'dashboard.funnel.automation_rules.delete_rule.confirmation'}
                        components={{ b: <b /> }}
                      />
                    ) : (
                      t('dashboard.automation_rule.edit.delete_confirm')
                    )
                  }
                  opened={opened}
                  onClose={onClose}
                  onConfirm={() => deleteRule(instance)}
                />
              )
            },
          },
        ],
      }}
      data={rules}
      columns={columns}
    />
  )
}

export default FunnelStepRulesTable

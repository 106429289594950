import { DigitalResourcesTypeEnum } from 'modules/digital-product/enums/digital-resources-type-enum'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

interface BaseApiInterface {
  hasMore: false
  items: { id: number; name: string }[]
}

export interface ListingBaseDataInterface {
  id: number
  caption: string
}

export interface UseDigitalResourcesPropsInterface {
  resourceType?: DigitalResourcesTypeEnum
  courseId?: number | null
  courseBundleId?: number | null
}

export interface DigitalResourcesDataInterface {
  coursesData?: ListingBaseDataInterface[]
  courseBundlesData?: ListingBaseDataInterface[]
  plansData?: ListingBaseDataInterface[]
  tagsData?: ListingBaseDataInterface[]
  communitiesData?: ListingBaseDataInterface[]
  courseModulesData?: ListingBaseDataInterface[]
  courseBundleCoursesData?: ListingBaseDataInterface[]
}

const getData = (apiData?: BaseApiInterface): ListingBaseDataInterface[] | undefined =>
  apiData?.items.map(el => ({ id: el.id, caption: el.name }))

const getSimpleData = (
  apiData?: BaseApiInterface['items'],
): ListingBaseDataInterface[] | undefined => apiData?.map(el => ({ id: el.id, caption: el.name }))

export function useDigitalResources({
  resourceType,
  courseId,
  courseBundleId,
}: UseDigitalResourcesPropsInterface): DigitalResourcesDataInterface {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, BaseApiInterface>({
    method: RequestMethodsEnum.get,
  })

  const { fetcher: simpleFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    BaseApiInterface['items']
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: coursesData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.membership_course) {
      return '/api/dashboard/customer/membership/courses/list-all'
    }
    return null
  }, fetcher)

  const { data: courseModulesData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.membership_course && courseId) {
      return `/api/dashboard/customer/membership/courses/${courseId}/modules/list-all`
    }
    return null
  }, simpleFetcher)

  const { data: courseBundlesData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.membership_course_bundle) {
      return '/api/dashboard/customer/membership/course-bundles/list-all'
    }
    return null
  }, fetcher)

  const { data: courseBundleCoursesData } = useSWR(() => {
    if (
      user &&
      resourceType === DigitalResourcesTypeEnum.membership_course_bundle &&
      courseBundleId
    ) {
      return `/api/dashboard/customer/membership/course-bundles/${courseBundleId}/courses/list-all`
    }
    return null
  }, simpleFetcher)

  const { data: plansData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.systemeio_plan) {
      return '/api/dashboard/customer/user-plans/list/auto-upgrade'
    }
    return null
  }, fetcher)

  const { data: tagsData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.systemeio_tag) {
      return '/api/dashboard/customer/tags/list-all'
    }
    return null
  }, fetcher)

  const { data: communitiesData } = useSWR(() => {
    if (user && resourceType === DigitalResourcesTypeEnum.community_access) {
      return '/api/dashboard/community/community'
    }
    return null
  }, simpleFetcher)

  return {
    coursesData: getData(coursesData),
    courseBundlesData: getData(courseBundlesData),
    plansData: getData(plansData),
    tagsData: getData(tagsData),
    communitiesData: getSimpleData(communitiesData),
    courseModulesData: getSimpleData(courseModulesData),
    courseBundleCoursesData: getSimpleData(courseBundleCoursesData),
  }
}
